import SessionStore from "../../../services/session/SessionStore";
import BrandConfigStore from "../../../services/config/BrandConfigStore";
import { GOOGLE_POSTS_ONLY, GOOGLE_QA_ONLY } from "../../social/socialConstants";
import { AccountServices } from "../../../services/session/Authentication";
import { BrandConfigRestrictions } from "../../../services/config/BrandConfig";
import FeatureFlagStore from "../../../services/config/FeatureFlagStore";

// extracts only the boolean properties from a type
type PickBools<T> = { [K in keyof T as T[K] extends boolean ? K : never]: T[K] };

// boolean properties of account services
export type AccountServiceName = keyof PickBools<AccountServices>;

// boolean properties of BrandConfigRestrictions
export type BrandConfigRestrictionName = keyof PickBools<BrandConfigRestrictions>;

/**
 * require all of the permissions to be on the user
 * @param {string} permissions - permission strings, e.g. requirePermissions("ShowReviews", "ReviewTagManager")
 * @return {boolean} - true if the route is accessible
 */
export function requirePermissions(...permissions: string[]): boolean {
  return !!SessionStore.user && permissions.every((p) => SessionStore.user!.permissions.includes(p));
}

/**
 * like requirePermissions, but only requires at least one permission, not all
 * @param {string} permissions
 * @return {boolean} - true if the route is accessible
 */
export function requireOnePermission(...permissions: string[]): boolean {
  return !!SessionStore.user && permissions.some((p) => SessionStore.user!.permissions.includes(p));
}

/**
 * requirement where all of the specified account service keys must be enabled
 * @param {string} accountServiceNames - for example "listingManagement". in typescript syntax, `keyof AccountServices`
 *                                  See @type {AccountServices}
 * @return {boolean} - true if the route is accessible
 */
export function accountServices(...accountServiceNames: AccountServiceName[]): boolean {
  return !!SessionStore.account && accountServiceNames.every((s) => SessionStore.account!.services[s]);
}

export function hasAnyRestriction(...restrictions: BrandConfigRestrictionName[]): boolean {
  return (
    !!BrandConfigStore.brandConfig && // enables content only if BrandConfig is loaded, and every brandConfigRestriction is disabled
    // brand config restriction feature flags are backwards, in the form "hideMyFeatureName"
    // explicitly checks for false
    // this is to prevent accidentally exposing features from a new client deploy if the server is behind
    // (and therefore the restriction would be `undefined` which is falsy)
    restrictions.some((r) => BrandConfigStore.brandConfig?.restrictions[r] === true)
  );
}

/** checks that all of the given BrandConfigRestriction feature names are true */
export function featuresEnabled(...featureNames: BrandConfigRestrictionName[]): boolean {
  return (
    !!BrandConfigStore.brandConfig && // enables content only if BrandConfig is loaded, and every brandConfigRestriction is enabled
    featureNames.every((r) => BrandConfigStore.brandConfig?.restrictions[r])
  );
}

/**
 * This is a specialized function to handle "inverted" hide attributes. Special care needs to be taken here with
 * testing their values, as undefined being falsy could cause flicker where the feature is temporarily enabled
 * @param {string} restrictions - names of brand config restrictions that hide the tab `keyof BrandConfigRestrictions`,
 *                                for example "hideReviews". If any is hidden, the tab is disabled
 * @return {boolean} - true if the route is accessible
 */
export function hideIfAnyRestriction(...restrictions: BrandConfigRestrictionName[]): boolean {
  return (
    !!BrandConfigStore.brandConfig && // enables content only if BrandConfig is loaded, and every brandConfigRestriction is disabled
    // brand config restriction feature flags are backwards, in the form "hideMyFeatureName"
    // explicitly checks for false
    // this is to prevent accidentally exposing features from a new client deploy if the server is behind
    // (and therefore the restriction would be `undefined` which is falsy)
    restrictions.every((r) => BrandConfigStore.brandConfig?.restrictions[r] === false)
  );
}

/************************************
 * General feature access checks below
 */

/** generalFeatureRouteChecks */
export function snapshotAllowed(): boolean {
  return requirePermissions("ShowSnapshot") && hideIfAnyRestriction("hideSnapshot");
}

export function reviewsAllowed(): boolean {
  return requirePermissions("ShowReviews") && hideIfAnyRestriction("hideReviews");
}

export function reviewsStoreComparisonAllowed(): boolean {
  return reviewsAllowed() && hideIfAnyRestriction("hideReviewStoreComparison");
}

export function sentimentAllowed(): boolean {
  return requirePermissions("ShowSentiment") && hideIfAnyRestriction("hideSentiment");
}

export function pulseCustomTopicsAllowed(): boolean {
  // "pulseCustomTopics" is a developer restriction added just while we work on things.
  return sentimentAllowed() && featuresEnabled("pulseCustomTopics");
}

export function sentimentStoreComparisonAllowed(): boolean {
  return sentimentAllowed() && hideIfAnyRestriction("hidePulseStoreComparison");
}

export function mediaAllowed(): boolean {
  return requirePermissions("ShowMedia") && hideIfAnyRestriction("hideMedia");
}

export function LBTSAllowed(): boolean {
  return featuresEnabled("locationBasedTargetSelection");
}

/** returns whether the base social feature is allowed and enabled */
export function socialAllowed(): boolean {
  return requirePermissions("ShowChatter") && hideIfAnyRestriction("hideChatter2");
}

export function socialReportsAllowed(): boolean {
  return socialAllowed() && hideIfAnyRestriction("hideSocialStoreComparison");
}

export function socialAIAllowed(): boolean {
  return socialAllowed() && featuresEnabled("aiSocialGenerator");
}

/** returns whether tracking of social post engagement and metrics is enabled.
 * Allowed for all users if enabled */
export function socialEngagementAllowed(): boolean {
  return socialAllowed() && accountServices("socialEngagement");
}

/**
 * returns whether social commenting, the ability to respond to social posts from the activity stream,
 * is allowed and enabled.
 * "Allowed," in this context, means if the user has at least drafting capability.
 */
export function socialCommentingAllowed(): boolean {
  return (
    socialAllowed() &&
    accountServices("socialCommenting") &&
    requireOnePermission("SocialCommenter", "SubmitSocialCommenter") &&
    !socialGooglePostsOnly() &&
    !socialGoogleQAOnly()
  ); //If these are on you can't reply to facebook posts
}

/**
 * returns whether social publishing, the ability to schedule and publish posts to business pages.
 * is allowed and enabled.
 * "Allowed," in this context, means if the user has at least drafting capability.
 */
export function socialPublishingAllowed(): boolean {
  return (
    (socialAllowed() &&
      accountServices("socialPublishing") &&
      requireOnePermission("SocialPoster", "SubmitChatterPost")) ||
    socialGooglePostsOnly() ||
    socialGoogleQAOnly()
  );
}

export function socialGooglePostsOnly(): boolean {
  return hasAnyRestriction(GOOGLE_POSTS_ONLY);
}

export function socialGoogleQAOnly(): boolean {
  return hasAnyRestriction(GOOGLE_QA_ONLY);
}

export function showNewSocialPublishingList(): boolean {
  return featuresEnabled("showSocialPublishingList");
}

export function showAppleOnSocial() {
  return featuresEnabled("appleShowcases");
}

export function useAppleDelegationCredentials() {
  return featuresEnabled("appleApiDelegationCredentials");
}

export function listingManagementEnabled() {
  return accountServices("listingManagement");
}

export function appleListingManagement() {
  return accountServices("appleListingManagement");
}

export function bingListingManagement() {
  return accountServices("bingListingManagement");
}

export function facebookListingManagement() {
  return accountServices("facebookListingManagement");
}

export function googleListingManagement() {
  return accountServices("googleListingManagement");
}

export function yelpListingManagement() {
  return accountServices("yelpListingManagement");
}

/**
 * Returns whether social publishing, the ability to schedule and publish posts to business pages.
 * is allowed and enabled, and whether posts will be approved once posted. This does not include
 * users with only drafting capability.
 */
export function socialApprovedPublishingAllowed(): boolean {
  return socialAllowed() && accountServices("socialPublishing") && requirePermissions("SocialPoster");
}

/**
 * returns whether the user is allowed to do any social administration
 */
export function socialSettingsAllowed(): boolean {
  return socialAllowed() && requireOnePermission("ManageExternalAccounts", "AddExternalAccounts");
}

/**
 * returns true if google listing management is enabled for the active user's account
 */
export function googleListingManagementAllowed(): boolean {
  return accountServices("googleListingManagement");
}

export function listingsAllowed(): boolean {
  return requirePermissions("ShowListings") && hideIfAnyRestriction("hideListings");
}

export function listingOptimizationAllowed(): boolean {
  return listingsAllowed() && hideIfAnyRestriction("hideListingOptimizationReport");
}

export function listingMissingReportAllowed(): boolean {
  return listingsAllowed() && hideIfAnyRestriction("hideListingMissingReport");
}

export function listingDuplicatesReportAllowed(): boolean {
  return listingsAllowed() && hideIfAnyRestriction("hideListingDuplicatesReport");
}

export function listingSearchAnalyticsReportAllowed(): boolean {
  return (
    listingsAllowed() &&
    requirePermissions("ShowGoogleInsights") &&
    hideIfAnyRestriction("hideSearchAnalyticsStoreComparison")
  );
}

export function listingAccuracyReportAllowed(): boolean {
  return listingsAllowed() && hideIfAnyRestriction("hideListingManagementAccuracyReport");
}

export function reviewBuilderAllowed(): boolean {
  return requirePermissions("ReviewBuilderAccess") && hideIfAnyRestriction("hideReviewGeneration");
}

export function revisedReviewReportingAllowed(): boolean {
  return featuresEnabled("revisedReviewReporting");
}

export function rankingsAllowed(): boolean {
  return requirePermissions("ShowRankings") && hideIfAnyRestriction("hideRankings");
}

export function workflowAllowed(): boolean {
  return requirePermissions("ShowWorkflow") && hideIfAnyRestriction("hideWorkflow2");
}

export function notificationSettingsAllowed(): boolean {
  return requirePermissions("NotificationSettingsAdmin") && hideIfAnyRestriction("hideNotificationsSettings");
}

export function scoringAllowed(): boolean {
  return requirePermissions("ShowScoreReport") && hideIfAnyRestriction("hideScores", "hideScores2");
}

export function scoringStoreComparisonAllowed(): boolean {
  return scoringAllowed() && hideIfAnyRestriction("hideScoresStoreComparison");
}

export function workflowAdminAllowed(): boolean {
  return workflowAllowed() && requirePermissions("TaskAdmin");
}

export function emailAlertsAllowed(): boolean {
  return (
    requirePermissions("ShowEmailAlerts") && requirePermissions("ShowInsights") && hideIfAnyRestriction("hideInsights")
  );
}

export function emailAlertsAdminAllowed(): boolean {
  return requirePermissions("EmailAlertManagement");
}

export function analyticsStudioAllowed(): boolean {
  return requirePermissions("ShowInsights") && hideIfAnyRestriction("hideInsights");
}

export function isGlobalAdmin(): boolean {
  return requirePermissions("GlobalAccess");
}

export function userManagementAllowed(): boolean {
  return requirePermissions("UserManagement");
}

export function groupManagementAllowed(): boolean {
  return requirePermissions("GroupManagement");
}

export function scoreManagementAllowed(): boolean {
  return requirePermissions("ReadScoreConfig", "EditScoreConfig") && hideIfAnyRestriction("hideScores", "hideScores2");
}

export function locationManagementAllowed(): boolean {
  return requireOnePermission("AddLocation", "EditLocation", "DeleteLocation", "RestoreLocation");
}

export function accountManagementAllowed(): boolean {
  return requirePermissions("AccountManagement");
}

export function reportManagementAllowed(): boolean {
  return requirePermissions("ReportManagement");
}

export function brandManagementAllowed(): boolean {
  return requirePermissions("BrandManagement");
}

export function roleManagementAllowed(): boolean {
  return requirePermissions("AccountOwner", "RoleCreation");
}

export function isActionableInsightsEnabled(): boolean {
  return (
    !FeatureFlagStore.isEnabled("NewSnapshotPage") &&
    featuresEnabled("actionableInsights") &&
    requirePermissions("ShowPromotersDetractors")
  );
}

export function isSignalsEnabled(): boolean {
  return featuresEnabled("signals");
}

export function isWebMdReviewEnabled(): boolean {
  return featuresEnabled("webMdReviewFlag");
}

export function isRiskMonitoringEnabled(): boolean {
  return featuresEnabled("riskMonitoring") && requirePermissions("ShowRiskMonitoring");
}

export function enableAllProvidersInSignalsAI(): boolean {
  return featuresEnabled("enableAllProvidersInSignalsAI");
}

export function credentialManagementAllowed(): boolean {
  return requireOnePermission("ManageExternalAccounts", "AddExternalAccounts");
}

export function instantAuditsAllowed(): boolean {
  return requirePermissions("InstantAuditsAdmin");
}

export function uploadToolAllowed(): boolean {
  // show if the preference is enabled for the account, or if the user was impersonated by an internal admin.
  // This is kinda hacky. Transitioned from a kinda hacky alternative where there was a secret url param added to the locations editor
  // that unlocked the upload tool.
  return hideIfAnyRestriction("hideUploadTool") || isImpersonatedByGlobalAccessAdmin();
}

export function isImpersonatedByGlobalAccessAdmin(): boolean {
  return !!SessionStore.user?.impersonatedByUser?.internalAdmin;
}

// TODO - Use this for local pages reports and location editor
export function localPagesAllowed(): boolean {
  // show if the preference is enabled for the account, or if the user was impersonated by an internal admin.
  return accountServices("localPages") && requirePermissions("LocalPagesManagement");
}

export function hasRestaurantMenus(): boolean {
  return hideIfAnyRestriction("hideRestaurantMenus");
}

export function hasSmartResponse(): boolean {
  return requirePermissions("ReviewResponseGeneration") && hideIfAnyRestriction("hideReviewResponseGeneration");
}

export function hasAIReviewResponses(): boolean {
  return requirePermissions("ReviewResponseGeneration") && featuresEnabled("aiReviewResponses");
}

export function disableLocationAllowed(): boolean {
  return requirePermissions("DeleteLocation");
}

export function enableLocationAllowed(): boolean {
  return requirePermissions("RestoreLocation");
}

export function addLocationAllowed(): boolean {
  return requirePermissions("AddLocation");
}

export function editLocationAllowed(): boolean {
  return requirePermissions("EditLocation");
}

export function isInternational(): boolean {
  return featuresEnabled("internationalRestrictionsEnabled");
}

export function competitorsAllowed(): boolean {
  return hideIfAnyRestriction("hideCompetitors");
}

// Regardless of the toggle, don't show the managed and monitored tabs if LLM is turned off for the account. See COFE-1424.
export function hasListingsToggleAndLlmEnabled(): boolean {
  return hasAnyRestriction("listingsReportingToggle") && accountServices("listingManagement");
}

export function cloneSocialPostsAllowed(): boolean {
  return featuresEnabled("cloneSocialPosts");
}

export function editTopicsAllowed(): boolean {
  return requirePermissions("EditYourTopics");
}

export function modifyRisksAllowed(): boolean {
  return requirePermissions("ModifyRisk");
}

export function surveysAllowed(): boolean {
  return reviewBuilderAllowed() && featuresEnabled("surveys");
}

export function surveyReportingView(): boolean {
  return requireOnePermission("SurveyReportingView");
}

export function socialCustomSearchesAllowed(): boolean {
  return featuresEnabled("socialCustomSearches");
}

export function listingPublishStatusReportAllowed(): boolean {
  return listingManagementEnabled() && featuresEnabled("listingPublishErrors");
}

export function oloIntegrationAllowed(): boolean {
  return featuresEnabled("oloIntegration");
}

export function LLMSocialLinksAllowed(): boolean {
  return FeatureFlagStore.isEnabled("LLMSocialLinks");
}

export const showBillingForNewPackages = () => FeatureFlagStore.isEnabled("billing-for-new-packages");

export const openTableReviewIntegrationAllowed = () => FeatureFlagStore.isEnabled("OpenTableReviewsIntegration");

export function openTableApiAccess(): boolean {
  return featuresEnabled("openTableApi");
}

export function canEditTags(): boolean {
  return requirePermissions("TagEditing");
}

export function brandCompetitorUIEnabled(): boolean {
  return featuresEnabled("competitorBrandUI");
}

export function DisplayAlternateId(): boolean {
  return featuresEnabled("alternateId");
}

export function showNewSnapshotPage(): boolean {
  return FeatureFlagStore.isEnabled("NewSnapshotPage");
}

export function threadsIntegrationEnabled(): boolean {
  return FeatureFlagStore.isEnabled("ThreadsIntegration");
}

export function kyruusIntegration(): boolean {
  return FeatureFlagStore.isEnabled("KyruusIntegration");
}

export function reviewGenEnabled(): boolean {
  return FeatureFlagStore.isEnabled("ReviewGen");
}

export function surveysQrCodeEnabled(): boolean {
  return FeatureFlagStore.isEnabled("QRCodeGenerator");
}

export function competitorIntelEnabled(): boolean {
  return FeatureFlagStore.isEnabled("CompetitorSWOT");
}
